<template>
  <div class="container">
    <div class="box">
      <div class="left">
        <div class="logo-container">
          <img src="@/assets/img/logo-white.png" alt="" class="logo">
        </div>
        <div class="copyright mb-2">© AI-ShopMaster有限公司 版權所有，不得轉載。</div>
      </div>
      <div class="right">
        <div v-for="(item,idx) in itemList" :key="idx" class="itemList">
          <img :src="require('@/assets/icon/footer/'+item.icon)" alt="" class="icon">
          <span>{{ item.text }}</span>
        </div>
        <div class="bottom-text">
          <span v-for="(text) in textList" :key="text" class="rightText" @click="navigate(text)">{{ text }}</span>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      iconList: ['twitter', 'facebook', 'youtube', 'linkedin', 'instagram'],
      MenuList: [
        { name: 'About', subMenu: ['Group', 'Careers', 'Digital Accessibility', 'Brand Protection'] },
        { name: 'Stores', subMenu: ['New York', 'Los Angeles', 'Munchen', 'Paris'] },
        { name: 'Follow', subMenu: ['Instragram', 'Fackbook', 'Twitter', 'Linkedin'] },
        { name: 'Politics', subMenu: ['Sitemap', 'Terms Conditions', 'Policy privacy', 'Returns'] },
      ],
      itemList: [
        {icon:"location.png",text:"香港九龍xx區xx道xx大廈xx樓xx室"},
        {icon:"phone.png",text:"+852 xxxx xxxx"},
        {icon:"email.png",text:"enquiry@aishopmaster.com"},
      ],
      textList: ['隱私條款','網站地圖','常見問題']
    }
  },
  methods:{
    navigate(text){
      if(text=='常見問題'&&this.$route.path!='/questions'){
        this.$router.push('/questions');
      }
      else if(text === '網站地圖'){
        const mapUrl = '/navination';
        this.$route.path == mapUrl ?  this.scrollToTop() : this.$router.push('/navination')
      }
    },
    scrollToTop() {
      this.$nextTick(() => {
        // 滚动到顶部（同步操作）  
        window.scrollTo({
          top: 0,
          // behavior: 'smooth'
        });
      })
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  background-color: var(--main-color);
  background-color: #404040;
  padding: 30px 8%;
  color: #fff;
  /* color: #ffffff80;   */
}

.box{
  display: flex;
}

.left {
  line-height: 30px;
  flex: 3;
  padding-right: 20px;
  border-right: 1px solid #fff;
}

.logo-container{
  height: 80%;
  display: flex;
  align-items: center;
}


.logo{
  /* margin-top: 40px; */
  width: 320px;
  /* height: 80px; */
}

.right {
  flex: 3;
  /* padding-left: 80px; */
  padding-left: 8%;
}

.itemList{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon{
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.bottom-text{
  margin-top: 35px;
}

.rightText{
  margin-right: 10%;
  cursor: pointer;
}


@media (max-width: 768px) {
  .container{
    padding: 20px;
    display: flex;
    justify-content: center;
  }

  .box {
    display: block;
    
  }
  
  .logo{
    margin-bottom: 20px;
  }

  .left{
    border-right: none;
    padding-right: 0;
  }

  .right {
    margin-top: 30px;
    padding-left: 0;
  }

  .rightText{
    margin-right: 50px;
  }
}
</style>
