// directives/lazy.js
export default {
  inserted(el, binding) {
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          binding.value();
          observer.unobserve(el);
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.1
    });

    observer.observe(el);
  }
}
