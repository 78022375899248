import Vue from 'vue'
import App from './App.vue'
import lazy from './directives/lazy.js'

import './assets/css/main.css'  
import './assets/css/fonts.css';  
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'  

// 基于断点的隐藏类
import 'element-ui/lib/theme-chalk/display.css';


/* 路由 */
import router from './router'

/* swiper */
import 'swiper/dist/css/swiper.min.css';

import VueCountTo from 'vue-count-to';  
  
Vue.use(VueCountTo);
Vue.use(VueRouter)
Vue.use(ElementUI);


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title + ' - AI-ShopMaster'
  }
  next();
});

// 全局后置守卫  
// router.afterEach((to, from) => {  
//   // 滚动到顶部  
//   window.scrollTo({  
//     top: 0,  
//     behavior: 'smooth'  
//   });  
// });  

Vue.directive('lazy', lazy)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
